@mixin section-content-center-with-image-title {
  .section-content {
    @include VHCenter;
  }

  .center {
    .top {
      color: #fff;
      text-align: center;

      img {
        height: 1.75rem;
        vertical-align: sub;
      }
    }

    .bottom {
      padding: 0 0.5rem;
      border: 2px solid #fff;
      border-top: none;
      border-bottom: none;
      font-style: italic;
      cursor: pointer;
    }
  }
}

.tpm-TiKV {
  .tikv-title {
    padding-top: 3rem;
    text-align: center;
  }

  .tikv-map {
    position: relative;
    min-width: 1408px !important;
    padding-bottom: 3rem;
    transform: translateX(5rem) scale(0.9);
    z-index: 999;

    @media screen and (min-width: 1650px) {
      right: (100% / 12);
    }

    &.columns {
      margin-bottom: 0;
    }

    .map-left {
      text-align: center;

      .block-grpc {
        width: 80%;
        height: 924px;

        .title {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .arrow-wrapper-external-request,
      .arrow-wrapper-return-result {
        position: absolute;
        left: -5rem;
      }

      .arrow-wrapper-external-request {
        top: 3rem;

        &:before {
          @extend .clockwise-90;

          content: 'Requests from TiKV Client';
          position: absolute;
          top: 55px;
          left: -35px;
          width: 150px;
        }
      }

      .arrow-wrapper-return-result {
        bottom: 6rem;

        &:before {
          @extend .counterclockwise-90;

          content: 'Result';
          position: absolute;
          top: 65px;
          left: -105px;
          width: 200px;
        }
      }
    }

    .map-center {
      position: relative;
      display: flex;
      flex-direction: column;

      .arrow-wrapper-write-request {
        position: relative;

        &:before {
          @extend .clockwise-90;

          content: 'Write/Read request';
          position: absolute;
          top: 45px;
          left: -36px;
          width: 140px;
        }
      }

      .section-scheduler-pool {
        @include section-content-center-with-image-title;
      }

      .arrow-wrapper-latch-got {
        position: relative;

        &:before {
          @extend .clockwise-90;

          content: 'Latch got';
          position: absolute;
          top: 40px;
          left: 0;
        }

        .async-write {
          @extend .clockwise-90;

          position: absolute;
          top: 40px;
          left: -55px;
        }
      }

      .section-raftstore-pool {
        @include section-content-center-with-image-title;
      }

      .arrow-wrapper-replicate-to {
        position: absolute;
        left: 300px;
        width: 20px;

        &:before {
          @extend .counterclockwise-90;

          content: 'Replicate to other TiKVs';
          position: absolute;
          top: 270px;
          left: -100px;
          width: 180px;
        }

        &:after {
          content: '';
          position: absolute;
          top: 10px;
          left: -71px;
          width: 80px;
          height: 3px;
          background: #fff;
        }
      }

      .coprocessor-readpool,
      .storage-readpool {
        display: flex;
        margin-top: 12rem;

        > div {
          &:first-child {
            position: relative;
            left: 4rem;
          }

          &:nth-child(3) {
            position: relative;
            left: 3rem;
          }
        }
      }

      .arrow-wrapper-push-down-read {
        position: relative;

        &:after {
          @extend .counterclockwise-90;

          content: 'Read pushdown';
          position: absolute;
          top: 65px;
          left: -76px;
          width: 120px;
        }

        .coprocessor-wait {
          @extend .counterclockwise-90;

          position: absolute;
          top: 65px;
          left: -25px;
          width: 130px;
        }
      }

      .section-coprocessor-readpool,
      .block-storage-readpool {
        width: 60%;
        margin-left: 11rem;
      }

      .section-coprocessor-readpool {
        @include section-content-center-with-image-title;
      }

      .arrow-wrapper-async-snapshot {
        position: relative;

        &:after {
          @extend .counterclockwise-90;

          content: 'Async snapshot';
          position: absolute;
          top: 38px;
          left: -76px;
          width: 120px;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .arrow-wrapper-kv-read {
        position: relative;

        &:after {
          @extend .counterclockwise-90;

          content: 'KV read';
          position: absolute;
          top: 38px;
          left: -76px;
          width: 120px;
        }
      }

      .block-storage-readpool {
        @include VHCenter;

        height: 150px;
      }
    }

    .map-right {
      position: relative;

      .arrow-wrapper-append-log {
        position: relative;

        .append-log {
          @extend .clockwise-90;

          position: absolute;
          top: 40px;
          left: -55px;
        }
      }

      .block-wrapper-raft-rocksdb {
        @include VHCenter;

        width: 200px;
        height: 180px;
      }

      .section-apply-thread-pool {
        @include section-content-center-with-image-title;
      }

      .arrow-wrapper-apply-log {
        position: absolute;
        right: 6rem;

        .apply-log {
          position: absolute;
          top: 40px;
          left: -85px;
        }
      }

      .section-kv-rocksdb {
        position: absolute;
        right: 0.75rem;
        margin-top: 10rem;

        .columns {
          height: 100%;
          margin: 0;
        }

        .section-left {
          display: flex;
          flex-direction: column;

          .top,
          .bottom {
            display: flex;
            flex: 1;

            .left {
              position: relative;
              flex: 0.8;

              .arrow-wrapper-read-path-1,
              .arrow-wrapper-read-path-2 {
                position: absolute;
                left: 2.5rem;
              }

              .arrow-wrapper-read-path-1 {
                bottom: 0.1rem;

                &:before {
                  @extend .counterclockwise-90;

                  content: 'Read path 1';
                  position: absolute;
                  top: 40px;
                  right: 0;
                  width: 80px;
                }
              }

              .arrow-wrapper-read-path-2 {
                top: -1.6rem;

                &:after {
                  @extend .counterclockwise-90;

                  content: 'Read path 2';
                  position: absolute;
                  top: 40px;
                  left: 0;
                  width: 80px;
                }
              }
            }

            .right {
              display: flex;
              flex-direction: column;
              align-items: center;
              flex: 1.2;

              > * {
                margin-bottom: 1rem;
              }

              .tpm-Block {
                width: 160px;
              }

              .block-mem-table {
                margin-bottom: 2rem;
              }

              .arrow-wrapper-block-cache {
                &:after {
                  @extend .clockwise-180;

                  content: 'Read path 3-2';
                  position: absolute;
                  top: 20px;
                  right: 40px;
                  width: 100px;
                }
              }
            }
          }
        }

        .section-right {
          display: flex;
          flex-direction: column;

          .top,
          .bottom {
            display: flex;
            flex: 1;
          }

          .top {
            .left,
            .right {
              position: relative;
            }

            .left {
              flex: 0.6;

              .arrow {
                position: absolute;
                bottom: -1.4rem;
                left: 1.5rem;
              }
            }

            .right {
              @include VHCenter;

              flex: 1.4;

              .compaction,
              .ssts {
                position: relative;
                top: 48px;
                padding: 0.25rem 0.75rem;
              }

              .compaction {
                border: 2px solid #fff;
              }

              .ssts {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 100px;
                border: 2px solid #fff;
                border-top: none;

                > div {
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  flex-wrap: wrap-reverse;
                  width: 60%;
                }
              }
            }
          }

          .bottom {
            position: relative;

            .arrow-wrapper {
              position: absolute;
              bottom: 0;
              left: 90px;

              &:after {
                @extend .counterclockwise-45;

                content: 'Read path 3-1';
                position: absolute;
                top: 80px;
                left: 20px;
                width: 95px;
              }
            }
          }
        }
      }
    }
  }

  .arrow-wrapper-external-request,
  .arrow-wrapper-return-result,
  .block-grpc,
  .arrow-wrapper-write-request,
  .section-scheduler-pool,
  .arrow-wrapper-latch-got,
  .section-raftstore-pool,
  .arrow-wrapper-append-log,
  .block-wrapper-raft-rocksdb,
  .arrow-wrapper-after-block-raft-rocksdb,
  .section-apply-thread-pool,
  .arrow-wrapper-apply-log,
  .section-kv-rocksdb,
  .arrow-wrapper-async-snapshot,
  .arrow-wrapper-bottom-async-snapshot,
  .section-coprocessor-readpool,
  .block-storage-readpool,
  .arrow-wrapper-replicate-to,
  .arrow-wrapper-push-down-read,
  .arrow-wrapper-kv-read {
    opacity: 0;
  }
}

.tpm-TiKV-Section {
  display: flex;
  flex-direction: column;

  .section-title {
    margin-bottom: 0;
    padding: 0.5rem;
    background: $TiKVTitleBackgroundColor;
    text-align: center;
  }

  .section-content {
    flex: 1;
    background: $TiKVContentBackgroundColor;
    font-size: 0.875rem;
  }
}

.tpm-TiDB {
  .tidb-title {
    padding-top: 3rem;
    text-align: center;
  }

  .tidb-map {
    min-width: 1408px !important;
    padding-bottom: 3rem;
    transform: scale(0.9);
    z-index: 999;

    &.columns {
      margin-bottom: 0;
    }

    .map-left {
      text-align: center;

      .block-client {
        width: 80%;
        height: 400px;

        .title {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .map-center-left {
      position: relative;

      .arrow-wrapper-sql,
      .arrow-wrapper-results {
        position: absolute;
        left: 100px;
      }

      .arrow-wrapper-sql {
        top: 0;

        &:before {
          @extend .clockwise-90;

          content: 'SQL';
          position: absolute;
          top: 90px;
          left: 15px;
        }
      }

      .arrow-wrapper-results {
        top: 250px;

        &:before {
          @extend .counterclockwise-90;

          content: 'Return results in SQL format';
          position: absolute;
          top: 80px;
          left: -80px;
          width: 110px;
        }
      }
    }

    .map-center-right {
      position: relative;

      .section-server-entry {
        position: relative;
        margin-bottom: 70px;

        .top {
          display: flex;
          flex-direction: column;

          .session {
            display: flex;
            align-items: center;
            height: 60px;

            > * {
              @include VHCenter;

              flex: 1;
            }

            .active-session {
              flex: 1.5;
              height: 1rem;
            }

            .session-to-token {
              position: relative;

              &:before {
                content: '<2us';
                position: absolute;
                top: 12px;
                left: 15px;
              }
            }
          }

          .session-count {
            padding-left: 0.6rem;
            margin-bottom: 10px;
          }
        }

        .bottom {
          padding-left: 0.6rem;
        }
      }

      .section-executor {
        .section-content {
          display: flex;
          flex-direction: column;
        }

        .top,
        .bottom {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          margin-left: 1.2rem;
        }

        .bottom {
          padding-bottom: 0.5rem;
        }
      }

      .arrow-wrapper-get-token {
        position: absolute;
        top: 40px;
        right: 120px;

        &:before {
          @extend .clockwise-90;

          content: 'Token Got';
          position: absolute;
          top: 50px;
          left: -10px;
          width: 80px;
        }
      }

      .arrow-wrapper-pointget {
        position: absolute;
        top: 170px;
        right: -50px;
        width: 100px;
        cursor: pointer;

        &:before {
          content: 'PointGet';
          position: absolute;
          top: 30px;
          left: 30px;
          width: 70px;
          text-decoration: underline;
        }
      }

      .in-dashed-box {
        position: relative;
        width: 440px;
        margin: 0;

        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0.75rem 0;
        }

        .corprocessor {
          padding: 0.5rem 0;
        }

        .above-distsql,
        .above-kv {
          margin-bottom: 140px;
        }

        .section-distsql,
        .section-kv {
          position: absolute;
          top: 520px;
          width: 200px;
          height: 30px;
          padding: 0.1rem;

          .section-content {
            display: flex;
            align-items: center;

            p {
              margin-left: 0.6rem;
            }
          }
        }

        .section-distsql {
          left: -40px;
        }

        .operator-pushdown {
          @extend .tooltip;

          position: absolute;
          top: 350px;
          left: -150px;
        }

        .section-kv {
          left: 230px;
        }

        .above-transaction-construction {
          margin-bottom: 330px;
        }

        .section-transaction-construction {
          position: absolute;
          top: 130px;
          right: -300px;
          z-index: 1;

          .section-content {
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;

            .top,
            .center,
            .bottom {
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex: 1;
              margin-left: 1.2rem;
            }
          }
        }
      }

      .section-tikv-client {
        .section-content {
          display: flex;
          align-items: center;

          p {
            margin-left: 1.2rem;
          }
        }
      }

      .section-pd-client {
        position: absolute;
        bottom: 0;
        right: -500px;
        margin: 0 0.75rem 0.75rem 0;
        z-index: 1;

        .section-content {
          display: flex;
          align-items: center;

          p {
            margin-left: 1.2rem;
          }
        }
      }

      .arrow-wrapper-send-request-to-tikv,
      .arrow-wrapper-return-result-tikv,
      .arrow-wrapper-send-request-to-pd,
      .arrow-wrapper-return-result-pd {
        position: absolute;
      }

      .arrow-wrapper-send-request-to-tikv {
        left: 287px;

        &:before {
          content: 'Send requests to TiKV';
          position: absolute;
          top: 40px;
          left: 30px;
          width: 200px;
        }
      }

      .arrow-wrapper-return-result-tikv {
        left: 152px;

        &:before {
          @extend .clockwise-180;

          content: 'Returns the result';
          position: absolute;
          top: 40px;
          left: 30px;
          width: 130px;
        }
      }

      .arrow-wrapper-send-request-to-pd {
        left: 822px;

        &:before {
          content: 'Send requests to PD';
          position: absolute;
          top: 40px;
          left: 30px;
          width: 200px;
        }
      }

      .arrow-wrapper-return-result-pd {
        left: 700px;

        &:before {
          @extend .clockwise-180;

          content: 'Returns the result';
          position: absolute;
          top: 40px;
          left: 30px;
          width: 130px;
        }
      }
    }

    .map-right {
      position: relative;

      .section-preprocess {
        position: absolute;
        left: -60px;

        .top,
        .bottom {
          margin-left: 0.6rem;
        }
      }

      .arrow-wrapper-ast {
        position: absolute;
        top: 170px;
        right: 180px;

        &:before {
          content: 'AST (Abstract Syntax Tree)';
          position: absolute;
          top: 30px;
          left: 30px;
          width: 200px;
        }
      }

      .section-sql-optimizer {
        position: absolute;
        top: 262px;
        right: -90px;

        .section-content {
          display: flex;

          .left,
          .right {
            @include VHCenter;

            flex-direction: column;
            flex: 1;
          }

          .l-to-p {
            position: relative;

            &:after {
              content: 'Logical plan';
              position: absolute;
              top: 15px;
              left: 30px;
              width: 80px;
            }
          }
        }
      }

      .arrow-wrapper-physical-plan {
        position: absolute;
        top: 270px;
        right: 390px;

        @media screen and (min-width: 1408px) {
          right: 385px;
        }

        &:before {
          @extend .counterclockwise-90;

          content: 'Physical plan';
          position: absolute;
          top: 62px;
          left: -60px;
          width: 100px;
        }

        &:after {
          @extend .counterclockwise-90;

          content: 'Read/Write';
          position: absolute;
          top: 66px;
          left: -10px;
          width: 80px;
        }
      }

      .arrow-wrapper-get-tso {
        position: absolute;
        bottom: 170px;
        right: 190px;
        width: 100px;

        &:before {
          content: 'Get TSO';
          position: absolute;
          top: 120px;
          left: 30px;
          width: 70px;
          text-decoration: underline;
        }
      }

      .arrow-wrapper-get-tso-negative {
        position: absolute;
        bottom: 170px;
        right: 370px;
      }
    }

    .dashed-box {
      position: absolute;
      top: 480px;
      right: -93px;
      width: 92%;
      height: 45%;
      border: 1px dashed #fff;
      z-index: -1;

      .dashed-box-title {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    }
  }

  // For animejs
  .block-client,
  .arrow-wrapper-sql,
  .section-server-entry,
  .arrow-wrapper-get-token,
  .section-preprocess,
  .arrow-wrapper-pointget,
  .arrow-wrapper-ast,
  .section-executor,
  .arrow-wrapper-physical-plan,
  .section-sql-optimizer,
  .arrow-to-corprocessor,
  .above-transaction-construction,
  .dashed-box,
  .corprocessor,
  .section-transaction-construction,
  .above-distsql-1,
  .operator-pushdown,
  .section-distsql,
  .arrow-wrapper-get-tso,
  .arrow-wrapper-get-tso-negative,
  .section-pd-client,
  .above-kv-2,
  .section-kv,
  .below-distsql,
  .section-tikv-client,
  .above-tikv-client-1,
  .above-tikv-client-2,
  .above-tikv-client-3,
  .above-distsql-2,
  .above-kv-1,
  .arrow-wrapper-results,
  .arrow-wrapper-send-request-to-tikv,
  .arrow-wrapper-return-result-tikv,
  .arrow-wrapper-send-request-to-pd,
  .arrow-wrapper-return-result-pd {
    opacity: 0;
  }
}

.tpm-TiDB-Section {
  display: flex;
  flex-direction: column;

  .section-title {
    margin-bottom: 0;
    padding: 0.5rem;
    background: $TiDBTitleBackgroundColor;
    text-align: center;
  }

  .section-content {
    flex: 1;
    background: $TiDBContentBackgroundColor;
    font-size: 0.875rem;
  }
}

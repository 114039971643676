@import '../../node_modules/bulma/bulma.sass';
@import 'common';

::-webkit-scrollbar {
  display: none;
}

body {
  background: $MainBackgroundColor;
  color: $MainFontColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.title,
  &.subtitle {
    color: $MainFontColor;
  }
}

.clockwise-15 {
  @include rotate(15deg);
}

.counterclockwise-15 {
  @include rotate(-15deg);
}

.clockwise-45 {
  @include rotate(45deg);
}

.counterclockwise-45 {
  @include rotate(-45deg);
}

.counterclockwise-70 {
  @include rotate(-70deg);
}

.clockwise-75 {
  @include rotate(75deg);
}

.counterclockwise-75 {
  @include rotate(-75deg);
}

.clockwise-90 {
  @include rotate(90deg);
}

.counterclockwise-90 {
  @include rotate(-90deg);
}

.clockwise-180 {
  @include rotate(180deg);
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in-out;
}

.tooltip {
  text-decoration: underline;
  cursor: pointer;
}

.tpm-Container {
  display: flex;
  align-items: center;

  min-height: 100vh;
  overflow: scroll;
}

@import 'Block';
@import 'Tooltip';
@import 'Up';
@import 'Replay';
@import 'Home';
@import 'Overview';
@import 'TiDB';
@import 'TiKV';

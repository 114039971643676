$MainBackgroundColor: #3b4355;
$MainFontColor: #fff;

$TiDBArchitectureColor: #9bc4ca;

$TiDBTitleBackgroundColor: #504eaf;
$TiDBContentBackgroundColor: #5660c0;

$TiKVTitleBackgroundColor: #615086;
$TiKVContentBackgroundColor: #8f7cb8;

$TooltipBackgroundColor: #4e5971;

@mixin VHCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin rotate($deg) {
  transform: rotate($deg);
}

@mixin tpm-Tooltip-mixin($color: $TooltipBackgroundColor) {
  max-width: 500px;
  background-color: $color !important;
  color: #fff !important;
  font-size: 1rem;
  opacity: 1 !important;
  pointer-events: auto !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  &.place-top {
    &:after {
      border-top-color: $color !important;
      border-bottom-color: $color !important;
    }
  }

  &.place-bottom {
    &:after {
      border-bottom-color: $color !important;
    }
  }
}

.tpm-Tooltip {
  @include tpm-Tooltip-mixin;
}

.tpm-tidb-Tooltip {
  @include tpm-Tooltip-mixin($TiDBTitleBackgroundColor);
}

.tpm-tikv-Tooltip {
  @include tpm-Tooltip-mixin($TiKVTitleBackgroundColor);
}

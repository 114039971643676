@mixin home-block-border-and-on-hover($color) {
  border: 1px solid $color;

  &:hover {
    background: transparent !important;

    .title {
      color: $color;
    }
  }
}

.tpm-Home {
  @include VHCenter;

  height: 100vh;
  background: url(./images/bg.jpg) no-repeat;
  background-size: cover;

  .home-descs {
    > p {
      margin-bottom: 0.5rem;
      color: rgba(#fff, 0.8);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .home-blocks {
    display: flex;
    padding-top: 2rem;

    .tpm-Block {
      margin-right: 3rem;
      border-radius: 3px;
      cursor: pointer;

      .title {
        font-weight: normal;
      }
    }

    .block-overview {
      @include home-block-border-and-on-hover($TiDBArchitectureColor);
    }

    .block-tidb {
      @include home-block-border-and-on-hover($TiDBContentBackgroundColor);
    }

    .block-tikv {
      @include home-block-border-and-on-hover($TiKVContentBackgroundColor);
    }
  }
}

@mixin layer {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px dashed #fff;

  .subtitle {
    @include make-desc-text-vertical-center;

    margin-left: 1rem;
  }
}

@mixin make-desc-text-vertical-center {
  height: 100px;
  margin-bottom: 0;
  line-height: 100px;
}

.tpm-Overview {
  .overview-title {
    padding: 3rem 0;
    text-align: center;
  }

  .overview-map {
    min-width: 1408px !important;

    .map-left {
      display: flex;
      flex-direction: column;
      align-items: center;

      > * {
        margin-top: 1rem;
        opacity: 0;
      }

      &:first-child {
        margin-top: 1rem;
      }

      .client-offset-for-tidb {
        transform: translateX(60px);
      }

      .client-to-load-balancer {
        display: flex;
        transform: translateX(80px);
        opacity: 1;

        > * {
          margin: 0 1rem;
        }

        .subtitle {
          @include make-desc-text-vertical-center;
        }

        .left,
        .right,
        .left-arrow,
        .right-arrow {
          opacity: 0;
        }
      }

      .load-balancer-offset-for-tidb {
        transform: translateX(60px);
      }

      .load-balancer-to-tidb {
        position: relative;
        transform: translateX(58px);

        .arrow {
          margin: 0 5rem;
        }

        .connection {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          cursor: pointer;
        }
      }

      .tidb-layer {
        @include layer;

        position: relative;

        .block-tidb {
          margin-left: 7.2rem;
        }

        .tidb-to-pd {
          position: absolute;
          bottom: -40px;
          right: -140px;
          // For animejs
          opacity: 0;

          .arrow-wrapper {
            image {
              transform: rotate(90deg);
              transform-origin: center;
            }
          }

          .arrow-wrapper-1 {
            position: relative;
            transform: translate3d(0, 5rem, 0);

            &:before {
              content: 'TSO/Data Location';
              position: absolute;
              top: 10px;
              left: -55px;
              width: 150px;
            }
          }
        }
      }

      .tidb-to-tikv {
        position: relative;
        transform: translateX(60px);

        .arrow {
          margin: 0 3rem;
        }

        .arrow-1 {
          transform: rotate(45deg);
        }

        .arrow-2 {
          transform: rotate(-45deg);
        }

        .arrow-3 {
          transform: rotate(45deg);
        }

        .arrow-4 {
          transform: rotate(-45deg);
        }

        .special-arrow {
          position: absolute;
          top: -50px;
          left: 240px;
        }

        .distsql,
        .kv {
          position: absolute;
          top: 34px;
          margin-bottom: 0;
          padding: 0.2rem 0.4rem;
          border: 1px solid #fff;
          border-radius: 30px;
          cursor: pointer;

          &:hover {
            background: #d6bdaa;
            color: #000;
          }
        }

        .distsql {
          left: -66px;
        }

        .kv {
          left: 26px;
          width: 100px;
          text-align: center;
        }
      }

      .tikv-layer {
        @include layer;

        position: relative;
        flex-wrap: wrap;
        height: 200px;

        .subtitle {
          height: 20px;
          line-height: 1;
          text-align: center;
        }

        $MarginTop: 20px;

        .descs {
          flex: none;
          margin-top: $MarginTop;
        }

        .block-tikv {
          margin-top: $MarginTop;
          margin-left: 5rem;
          border: 2px solid transparent;
        }

        .curve {
          position: relative;
          top: 10px;
          left: 225px;
          width: 200px;
          height: 100px;
          margin-left: 20px;
          background: url('./images/svgs/curve.svg') no-repeat;
          background-position: bottom;
          background-size: cover;

          &:after {
            content: 'Raft';
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .tikv-to-pd {
          position: absolute;
          bottom: 100px;
          right: -140px;
          // For animejs
          opacity: 0;

          .arrow-wrapper-1 {
            transform: translate3d(0, 5rem, 0);
          }

          .arrow-wrapper-2 {
            position: relative;

            &:after {
              content: 'Data Location';
              position: absolute;
              top: 60px;
              left: -35px;
              width: 150px;
            }
          }
        }
      }
    }

    .map-right {
      position: relative;

      // For animejs
      > * {
        opacity: 0;
      }

      .pd-layer {
        position: relative;
        top: 51%;
        display: flex;
        align-items: center;
        height: 300px;
        border: 1px dashed #fff;

        .pds {
          display: flex;
          flex-wrap: wrap-reverse;
          justify-content: space-around;
          align-items: center;
          width: 60%;
          height: 60%;
        }

        .block-pd {
          width: 60px;
          min-width: 60px;
          height: 60px;
          margin-left: 2rem;
          padding-top: 1.2rem;
          border: 2px solid transparent;

          .title {
            line-height: 0.8;
          }
        }

        .pd-desc-wrapper {
          @include VHCenter;

          width: 40%;
        }
      }
    }
  }
}

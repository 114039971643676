.tpm-Block {
  display: inline-block;
  min-width: 120px;
  padding: 0.8rem;
  text-align: center;

  &.hovered:hover {
    border: 2px solid #fff !important;
    cursor: pointer;
  }
}
